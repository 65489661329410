import React from 'react';

import './css/footer.css';

export function Footer() {
  return (
    <footer className="footer bg-gray-800 py-2 text-primary-content footer-center">
        <p>fresh-dent.cz</p>
    </footer>

  )
}

export default Footer