import React from 'react';

import './css/location.css';
import PhoneIcon from './img/phone-alt-solid.svg';
import LocationIcon from './img/map-marker-alt-solid.svg';
import EnvelopeIcon from './img/envelope-solid.svg';


export function Location() {
  return (
    <section id="kontakt" className="flex flex-col mt-12 sm:flex-row text-black">
        <div className="sm:w-1/2 bg-modra text-white justify-center">
            <div className="text-center py-12 px-6 sm:p-24">
                <p className="font-bold text-2xl">
                    Kde nás najdete
                </p>

                <br />

                <p className="font-bold text-lg">
                    FreshDent
                    <br/>
                    Stomatologická ordinace
                    <br/>
                    MDDr. Michal Špenik
                </p>

                <p className="text-base">
                    sestra DiS Andrea Špeniková
                </p>

                <br />

                <div className="flex flex-col justify-center">
                    <p className="text-base font-bold">
                    Ordinační hodiny
                    </p>

                    <table className="self-center spacing-table">
                        <tbody>

                            <tr>
                            <td>pondělí</td>
                            <td>&nbsp;</td>
                            <td>8:30-15:00</td>
                            </tr>

                            <tr>
                            <td>úterý</td>
                            <td>&nbsp;</td>
                            <td>8:30-15:00</td>
                            </tr>

                            <tr>
                            <td>středa</td>
                            <td>&nbsp;</td>
                            <td>8:30-15:00</td>
                            </tr>

                            <tr>
                            <td>čtvrtek</td>
                            <td>&nbsp;</td>
                            <td>8:30-15:00</td>
                            </tr>

                            <tr>
                            <td>pátek</td>
                            <td>&nbsp;</td>
                            <td>8:30-12:00</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <br />
                
                <div className="flex flex-col justify-center items-center mx-4">
                    
                    <div className="flex my-2 flex-col items-center sm:flex-row">
                        <img className="w-6 h-6 mx-2" src={LocationIcon} alt="adresa" />
                        
                        <span className="text-base">
                            Havlíkova 49/1, Horní Jiřetín
                        </span>
                    </div>

                    <div className="flex my-2 flex-col items-center sm:flex-row">
                        <img className="w-6 h-6 mx-2" src={EnvelopeIcon} alt="mail" />
                        
                        <span className="text-base">
                            michal.spenik@gmail.com
                        </span>
                    </div>

                    <div className="flex my-2 flex-col items-center sm:flex-row">
                        <img className="w-6 h-6 mx-2" src={PhoneIcon} alt="telefon" />
                        
                        <span className="text-base sm:text-sm">
                            797 828 777
                        </span>
                    </div>

                </div>
            
            </div>
        </div>

        <div className="sm:w-1/2 bg-red-500">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10136.20616283473!2d13.5516315!3d50.5704059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x67404e9ddec13e8f!2sFresh%20Dent!5e0!3m2!1scs!2scz!4v1633630354820!5m2!1scs!2scz"
                    width="100%" 
                    height="100%"
                    frameBorder="0"
                    style={{border:0}}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    title="Naše lokace">
            </iframe>
        </div>

    </section>
  )
}

export default Location