import React from 'react';

import './css/navbar.css';
import PhoneIcon from './img/phone-alt-solid.svg';
import Logo from './img/fresh_dent_logo_very_wide_png.png';
import LocationIcon from './img/map-marker-alt-solid.svg';

export function Navbar() {
  return (
    <header className="Menu">
        <nav>
            <div className="navbar shadow-lg bg-white text-black ">
                
                <div className="hidden sm:px-2 sm:mx-2 sm:navbar-start sm:flex">
                    <div className="flex items-center mx-4">
                        <img className="w-4 h-4 mx-2" src={PhoneIcon} alt="telefon" />
                        
                        <span className="text-base sm:text-sm">
                            797 828 777
                        </span>
                    </div>

                    <div className="flex items-center mx-4">
                        <img className="w-4 h-4 mx-2" src={LocationIcon} alt="telefon" />
                        
                        <span className="text-base">
                            Havlíkova 49/1, Horní Jiřetín
                        </span>
                    </div>

                </div>

                <div className="place-items-center m-0-auto sm:flex sm:navbar-center">
                    <img className="logo-size max-h-16" src={Logo} alt="FreshDent" />
                </div> 

                <div className="hidden sm:flex sm:px-2 sm:mx-4 sm:navbar-end">
                    <div className="sm:items-stretch">

                        <a className="btn btn-ghost btn-sm rounded-btn mx-8 md:mx-4" href="/#o-nas">
                            O nás
                        </a> 

                        <a className="btn btn-ghost btn-sm rounded-btn mx-8 md:mx-4" href="/#cennik">
                            ceník
                        </a> 

                        <a className="btn btn-ghost btn-sm rounded-btn mx-8 md:mx-4" href="/#kontakt">
                            Kontakt
                        </a> 

                    </div>

                </div>

            </div>
        </nav>

        <div className="text-sm text-center text-blue-600 bg-blue-200 border border-blue-400 p-4 rounded-sm sm:text-base" role="alert">
            Pro enormní zájem bohužel máme stop stav. Poslední volná místa jsou pouze pro obyvatele Horního Jiřetina a Černic.
        </div>
    </header>
  )
}

export default Navbar