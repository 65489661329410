import React from 'react';

import './css/hero.css';

export function Hero() {
  return (
    <section className="w-full hero-mob text-black sm:hero">
        <div className="flex w-full h-95vh sm:h-600">
            <div className="bg-white px-2 flex items-center text-center sm:px-8 sm:px-12 sm:w-1/2 morphism-hero" >
                <div>
                    <p className="font-bold text-2xl sm:text-5xl">
                      Stomatologická ordinace
                    </p>

                    <br />

                    <p className="font-bold text-xl sm:text-3xl">
                      MDDr. Michal Špenik
                    </p>

                    <p className="font-normal text-lg sm:text-base">
                      sestra DiS Andrea Špeniková
                    </p>


                    <br />

                    <p className="font-medium text-xs sm:text-base">
                      Vítejte na stránkách naší zubní ordinace.
                      Čeká na vás rodinná atmosféra, nadstandardní péče i nejmodernější technologie na trhu.
                      Máme vysoké cíle a i díky nejnovějším postupům v oblasti zubního lékařství garantujeme nejšetrnější a bezpečné ošetření vašich zubů.
                    </p>

                </div>
            </div>
            
            <span className="hidden sm:flex sm:block sm:bg-white sm:bg-opacity-80 sm:w-1/2 sm:morphism-hero" style={{ clipPath: 'polygon(0 0, 0% 100%, 10% 100%)' }}></span>

        </div>
    </section>
  )
}

export default Hero