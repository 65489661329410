import React from 'react';

import './modal.css';
import $ from 'jquery';


$(function() {
  //open popup
  $('.popup').addClass('is-visible');
  
  //close popup
  $('.popup').on('click', function(event){
    if( $(event.target).is('.popup-close') || $(event.target).is('.popup') ) {
      event.preventDefault();
      $(this).removeClass('is-visible');
    }
  });
});


export function Vacation() {
  return (
    <section>
      <div className="popup" role="alert">
        
        <div className="popup-container sm:container-mob">
          <a href="#0" className="popup-close img-replace">Zatvorit</a>

          <div className="container mx-auto p-4">
              <div className="flex flex-col lg:flex-row space-x-4">
                  <div className="flex-1 p-1">
                    <p className="font-bold text-2xl sm:text-3xl">
                      DOVOLENÁ
                    </p>
                    
                    <br/>
                    <br/>

                    <p className="font-bold text-xl xs:text-xl">
                      Zubní ordinace neordinuje ve dnech
                      {/* Vážení pacienti,  */}
                      
                      {/* <br />
                      ordinace bude uzavřena ve dnech  */}

                      <br />
                      <br />
                      12.8.2024 - 23.8.2024
                    </p>

                    <br />

                    <p className="font-normal text-lg xs:text-base">
                      {/* V případě akutní bolesti navštivte pohotovost - Most, Bílina */}
                      V případě akutní bolesti našich registrovaných pacientů
                      <br />

                      <br />

                      <p className="font-bold text-xl xs:text-3xl">
                        MDDr. Václav Bílek <br /> 
                      </p>

                      <br />

                      Tržní 508, Litvínov <br />

                      každý den <br />
                      7:00 – 9:00 <br /><br />

                      
                      <p className="font-bold text-xl xs:text-3xl">
                        tel: 725 980 113
                      </p>
                      
                    </p>

                  </div>
                  <div className="flex-1 p-1">
                    <p className="font-bold text-xl xs:text-xl">
                      Akutní stavy:
                    </p>

                    <ol className="p-3 list-decimal text-justify list-inside space-y-2">
                      <li>Noční bolesti, tepavého charakteru, které nelze zklidnit analgetiky.</li>
                      <li>Otoky.</li>
                      <li>Bolestivé rozlomené zuby.</li>
                      <li>Úrazy zubů.</li>
                      <li>Bolestivá vypadlá nebo uvolněná výplň.</li>
                      <li>Trvající bolest zubu na kousání, prasklý zub, který ostrou hranou poškozuje měkké tkáně.</li>
                      <li>Viklavý bolestivý zub.</li>
                    </ol>
                    
                    <br/>

                    <p className="font-bold text-xl xs:text-xl">
                    Co není akutní stav:
                    </p>

                    <ol className="p-3 list-decimal text-justify list-inside space-y-2">
                      <li>Otlaky od náhrad (zuby do skleničky).</li>
                      <li>Afty.</li>
                      <li>Uvolněné výplně (plomby), pokud zub nebolí.</li>
                      <li>Jakékoliv poškození náhrad.</li>
                      <li>Uvolněná korunka (pokud zub nebolí).</li>
                      <li>Prasklý zub s ošetřenými kanálky – kořen.</li>
                      <li>Něco se mi nezdá, chci to zkontrolovat.</li>
                      <li>Jedu na dovolenou, chci to zkontrolovat.</li>
                      <li>Uvolněný můstek.</li>
                    </ol>
                  </div>
              </div>
          </div>
          
        </div> 

      </div> 

    </section>
  );
}

export default Vacation