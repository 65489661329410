import React from 'react';

import './css/services.css';
import icon1 from './img/icon01.png';
import icon2 from './img/icon02.png';
import icon3 from './img/icon03.png';
import icon4 from './img/icon04.png';

export function Services() {
  return (
    <section className="bg-yellow-300 w-full text-black">
        <div className="max-w-6xl mx-auto px-4 text-center justify-items-center sm:px-6 lg:px-4 py-12">
            <div className="w-full text-center pb-8">
                <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900 pb-2">
                    Naše služby
                </h1>
            </div>

            <div className="w-full flex flex-col sm:grid sm:grid-cols-2 sm:gap-6">

                <div className="service-info-bg my-6 p-6 flex flex-1 flex-col-reverse items-center sm:flex-row sm:my-0">

                    <div className="flex mx-4 my-4 px-8 max-width-100 sm:my-0">
                        <img className="object-center h-auto object-scale-down ms-150px" 
                        src={icon3} alt="" />
                    </div>

                    <div className="flex flex-col px-4 space-y-6 mb-4 items-center sm:items-stretch">
                        <p className="flex items-end text-center text-xl font-bold h-1/4">Prevence</p>
                        <p className="font-normal text-base mx-0 text-justify">
                            Pravidelné preventivní prohlídky každých šest měsíců jsou nezbytné pro včasné odhalení chorob nejen zubů, ale i dásní a sliznice dutiny ústní. Pokud se kaz ošetří jako malý, pak zákrok nemusí být bolestivý a zároveň se minimalizují komplikace v budoucnu. 
                            V pravidelných odstupech se opakují i RTG vyšetření.
                        </p>
                    </div>
                    
                </div>




                <div className="service-info-bg my-6 p-6 flex flex-1 flex-col-reverse items-center sm:flex-row sm:my-0">

                    <div className="flex mx-4 my-4 px-8 max-width-100 sm:my-0">
                        <img className="object-center h-auto object-scale-down ms-150px" 
                        src={icon1} alt="" />
                    </div>

                    <div className="flex flex-col px-4 space-y-6 mb-4 items-center sm:items-stretch">
                        <p className="flex items-end text-center text-xl font-bold h-1/4">Konzervační ošetření</p>
                        <p className="font-normal text-base mx-0 text-justify">
                            Toto ošetření slouží k odstranění zubního kazu a obnově mastikační (kousací) a estetické funkce zubu. 
                            Plak se vytváří na všech zubech u všech lidí, ale při nedostatečné hygieně, když není kvalitně odstraněn, způsobuje odvápnění, změknutí a probourání skloviny.
                            </p>
                    </div>
                    
                </div>

                <div className="service-info-bg my-6 p-6 flex flex-1 flex-col-reverse items-center sm:flex-row sm:my-0">

                    <div className="flex mx-4 my-4 px-8 max-width-100 sm:my-0">
                        <img className="object-center h-auto object-scale-down ms-150px" 
                        src={icon2} alt="" />
                    </div>

                    <div className="flex flex-col px-4 space-y-6 mb-4 items-center sm:items-stretch">
                        <p className="flex items-end text-center text-xl font-bold h-1/4">Dentální hygiena</p>
                        <p className="font-normal text-base mx-0 text-justify">
                            Součástí prevence je i dentální hygiena. 
                            Návštěva sice není povinná, ale velmi doporučena.
                            V ideálním případě by návštěva zubní hygienistky měla předcházet preventivním prohlídkám.
                        </p>
                    </div>
                    
                </div>


                <div className="service-info-bg my-6 p-6 flex flex-1 flex-col-reverse items-center sm:flex-row sm:my-0">

                    <div className="flex mx-4 my-4 px-8 max-width-100 sm:my-0">
                        <img className="object-center h-auto object-scale-down ms-150px" 
                        src={icon4} alt="" />
                    </div>

                    <div className="flex flex-col px-4 space-y-6 mb-4 items-center sm:items-stretch">
                        <p className="flex items-end text-center text-xl font-bold h-1/4">Ostatní</p>
                        <p className="font-normal text-base mx-0 text-justify">
                            Součástí naší ordinace je i CAD/CAM technologie.
                            Princip této technologie je založen na počítačové 3D modelaci protetického výrobku a následném vyfrézování z keramického bloku.
                        </p>
                    </div>
                    
                </div>

            </div>
        </div>
    </section>
  )
}

export default Services